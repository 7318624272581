// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import TransactionsTableLinkComponent from './components/link/transactions-table-link.component';
import TransactionsSecurityCheckComponent from './components/security-check/security-check.component';
import TransactionsTableListComponent from './components/list/transactions-table-list.component';
import { TransactionsTableHeaderComponent } from './components/table-header/transactions-table-header.component';
// Configurations
import { getTransactionsTableErrorConfiguration } from './configurations/transactions-table.configuration';
// Constants
import { ORGANISM_TABLE_TEST_ID_DEFAULT } from '../../constants/table-organism.constants';
// Hooks
import { TransactionsTableHook } from './hooks/transactions-table.hook';
// Styles
import {
  TransactionsTableOrganismStyled,
  TransactionsTableContentOrganismStyled,
  TransactionsTableBottomContentOrganismStyled,
} from './transactions-table.organism.styled';
// Types
import { TransactionsTableComponentType } from './types/transactions-table.component.type';
// Utilities
import { getTableOrganismPropsById } from '../../utils/table-organism.utils';

const TransactionsTableComponent = ({
  error,
  onClickLink,
  onClickSecurityCheckBtn,
  resolver,
  rowComponent,
  title,
  transactions,
  id,
  testId = ORGANISM_TABLE_TEST_ID_DEFAULT,
}: TransactionsTableComponentType): React.ReactElement<TransactionsTableComponentType> => {
  const {
    challenge,
    challengeError,
    elements,
    error: componentError,
    fetching,
    formatMessage,
    formValues,
    formValuesParams,
    handleFetchCardTransactions,
    handleNavigateToTransactionsViewButtonClick,
    handleTryAgainButtonClick,
    handleShowSecurityCheckModal,
    metadata,
    navigate,
    setChallenge,
    setChallengeError,
    setError,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setShowSecurityCheckModal,
    showSecurityCheckModal,
  } = TransactionsTableHook({
    error,
    resolver,
    transactions,
    idName: id.idName,
  });

  return (
    <TransactionsTableOrganismStyled {...{ testId }}>
      <TransactionsTableHeaderComponent
        {...{
          elements,
          formValues,
          formValuesParams,
          handleShowSecurityCheckModal,
          idTable: id.idTable,
          setCopyOfFormValues,
          setFormValues,
          setFormValuesParams,
          showSecurityCheckModal,
          title,
        }}
      />
      <TransactionsTableContentOrganismStyled {...{ fetching, error: componentError }}>
        <TransactionsTableBottomContentOrganismStyled>
          <FetchErrorComponent
            {...{ fetching }}
            error={getTransactionsTableErrorConfiguration({
              error: componentError,
              formatMessage,
              handleNavigateToTransactionsViewButtonClick,
              handleTryAgainButtonClick,
              navigate,
              idTable: id.idTable,
            })}
          >
            <TransactionsTableListComponent
              {...{
                hasGroups: getTableOrganismPropsById(id.idTable).hasGroups,
                rowComponent,
                groupId: id.groupId,
                elements,
                isFinancing: getTableOrganismPropsById(id.idTable).isFinancing,
              }}
            />
          </FetchErrorComponent>
          <TransactionsTableLinkComponent
            {...{
              metadata,
              componentError,
              onClick: onClickLink ?? handleFetchCardTransactions,
              innerPadding: getTableOrganismPropsById(id.idTable).innerPadding,
            }}
          />
          <TransactionsSecurityCheckComponent
            {...{
              onClickSecurityCheckBtn: onClickSecurityCheckBtn ?? handleShowSecurityCheckModal,
              metadata,
              componentError,
              challenge,
              challengeError,
              formValuesParams,
              handleFetchCardTransactions,
              setChallenge,
              setChallengeError,
              setError,
              setShowSecurityCheckModal,
              showSecurityCheckModal,
              serviceConfiguration: getTableOrganismPropsById(id.idTable).serviceConfiguration || {
                endpoint: '',
                queryParams: {},
              },
            }}
          />
        </TransactionsTableBottomContentOrganismStyled>
      </TransactionsTableContentOrganismStyled>
    </TransactionsTableOrganismStyled>
  );
};

export { TransactionsTableComponent };
