// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_DOCUMENT_BY_TYPE_CONFIGURATION } from './constants/transactions-table-header-link-bar-download-modal.services.constants';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalServicesType } from './types/transactions-table-header-link-bar-download-modal.services.type';

const getDocumentByTypeService = async ({
  documentType,
  cardId,
  formValuesParams,
}: TransactionsTableHeaderLinkBarDownloadModalServicesType): Promise<[any | null, Response]> =>
  apiUtilsCb.get({
    ...GET_DOCUMENT_BY_TYPE_CONFIGURATION,
    pathParams: { cardId },
    queryParams: { documentType, ...formValuesParams },
  });

export { getDocumentByTypeService };
