// Constants
import { NUMBER } from 'constants/number.constants';

const hasDecimals = (value: number | string): boolean => Number(value) % NUMBER.ONE !== NUMBER.ZERO;

const isMultipleOf = ({
  value,
  multipleOf,
}: {
  value: number | string;
  multipleOf: number;
}): boolean => Number(value) % multipleOf === NUMBER.ZERO;

export { hasDecimals, isMultipleOf };
