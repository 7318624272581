// Vendors
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import { getDocumentByTypeService } from '../services/transactions-table-header-link-bar-download-modal.services';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalResolverType } from './types/transactions-table-header-link-bar-download-modal.resolvers.type';
import { TransactionsTableHeaderLinkBarDownloadModalGetDocumentTypeResolverResponseType } from './types/transactions-table-header-link-bar-download-modal-return.resolvers.type';

const getDocumentByTypeResolver = async ({
  cardId,
  documentType,
  formValuesParams,
}: TransactionsTableHeaderLinkBarDownloadModalResolverType): Promise<
  [TransactionsTableHeaderLinkBarDownloadModalGetDocumentTypeResolverResponseType] | [null, number]
> => {
  const [response, { status }] = await getDocumentByTypeService({
    documentType,
    cardId,
    formValuesParams,
  });

  return wasSuccessRequest(status) ? [response] : [null, status];
};

export { getDocumentByTypeResolver };
