// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { RoutesEnumeration as RoutesEnumerationCC } from 'enumerations/routes.enumeration.cc';
// Constants
import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';
// Translations
import { NOTIFICATIONS_SETTINGS_TITLE } from 'containers/notifications/views/notifications-settings/translations/notifications-settings.translations';
import { PAGE_TITLE as AUTOMATIC_RENEWAL_TITLE } from 'containers/card-settings/views/renewal/translations/card-settings-renewal.translations.cc';
import { PAGE_TITLE as BENEFITS_INSURANCE_TITLE } from 'containers/card-settings/views/benefits-insurance/translations/card-settings-benefits-insurance.translations';
import { PAGE_TITLE as BLOCKED_CARDS_TITLE } from 'containers/card-settings/views/blocked-cards/translations/card-settings-blocked-cards.translations.cc';
import { PAGE_TITLE as CANCEL_CONTRACT_TITLE } from 'containers/support/views/cancel-contract/translations/support-cancel-contract-step.configuration.translations';
import { PAGE_TITLE as CHANGE_3D_TITLE } from 'containers/card-settings/views/change-3d/translations/card-settings-change-3d.translations';
import { PAGE_TITLE as CHANGE_BILLING_CYCLE_TITLE } from 'containers/iban-billings/views/billing-cycle/translations/iban-billings-billing-cycle.translations';
import { PAGE_TITLE as CHANGE_IBAN_TITLE } from 'containers/iban-billings/views/iban/translations/iban-billings-iban.translations';
import { PAGE_TITLE as CHANGE_PIN_TITLE } from 'containers/card-settings/views/change-pin/translations/card-settings-change-pin.translations';
import { PAGE_TITLE as CONTRACT_TITLE } from 'containers/card-settings/views/contract/translations/card-settings-contract.translations';
import { PAGE_TITLE as DEPOSIT_MONEY_TITLE } from 'containers/financing/views/deposit-money/translations/financing-deposit-money.translations';
import { PAGE_TITLE as DEVICES_TITLE } from 'containers/security-keys/views/devices/views/devices-manager/translations/devices-manager.translations';
import { PAGE_TITLE as DOCUMENTATION_TITLE } from 'containers/documentation/translations/documentation.translations';
import { PAGE_TITLE as EDIT_PHONE_NUMBER_TITLE } from 'containers/personal/views/number/translations/personal-number.translations';
import { PAGE_TITLE as FAQS_TITLE } from 'containers/support/views/faqs/translations/support-faqs.translations';
import { PAGE_TITLE as INCREASE_LIMIT_TITLE } from 'containers/financing/views/credit-limit-increase/translations/credit-limit-increase.translations';
import { PAGE_TITLE as MODIFY_LIMIT_TITLE } from 'containers/financing/views/credit-limit-modify/translations/credit-limit-modify.translations';
import { PAGE_TITLE as MANAGE_DEVICE_TITLE } from 'containers/security-keys/views/devices/views/device-detail/translations/device-detail.translations';
import { PAGE_TITLE as OTP_TITLE } from 'containers/otp/translations/otp.translations';
import { PAGE_TITLE as PACKAGES_TITLE } from 'containers/packages/translations/packages.translations';
import { PAGE_TITLE as PHOTO_TITLE } from 'containers/personal/views/photo/translations/personal-photo.translations';
import { PAGE_TITLE as PPI_CANCELLATION_TITLE } from 'containers/financing/views/ppi/components/cancellation/translations/financing-ppi-cancellation.translations';
import { PAGE_TITLE as PPI_CONTRACTING_TITLE } from 'containers/financing/views/ppi/components/contracting/translations/financing-ppi-contracting.translations';
import { PAGE_TITLE as PPI_TITLE } from 'containers/financing/views/ppi/translations/financing-ppi.translations';
import { PAGE_TITLE as RECOVERY_KEY_TITLE } from 'containers/recovery-key/translations/recovery-key.translations';
import { PAGE_TITLE as REPORT_A_PROBLEM_TITLE } from 'containers/transactions/views/report/translations/transactions-report.translations';
import { PAGE_TITLE as REQUEST_PARTNER_CARD_TITLE } from 'containers/card-settings/views/partner/translations/card-settings-partner.translations';
import { PAGE_TITLE as RESET_PIN_TITLE } from 'containers/card-settings/views/reset-pin/translations/card-settings-reset-pin.translations.cc';
import { TITLE as BLOCK_TITLE } from 'containers/card-settings/views/block-card/translations/card-settings-block-card.translations';
import { TITLE as CHECK_3D_TITLE } from 'containers/card-settings/views/check-3d/translations/card-settings-check-3d.translations';
import { TITLE as CONSENTS_TITLE } from 'containers/personal/views/consents/translations/profile-consents.translations';
import { TITLE as EARLY_REPAYMENT_TITLE } from 'containers/financing/views/early-repayment/translations/financing-early-repayment.translations';
import { TITLE as PAYMENT_METHOD_TITLE } from 'containers/financing/views/payment-method/translations/financing-payment-method.translations';
import { TITLE as REQUEST_NEW_CARD_TITLE } from 'containers/card-settings/views/new-card/translations/card-settings-new-card.translations';
import { TITLE as DENIED_MOVEMENTS_TITLE } from 'containers/transactions/views/denied/translations/transactions-denied.translations';
import * as editAddressTranslations from 'containers/personal/views/address/translations/personal-address.translations';
import * as editEmailTranslations from 'containers/personal/views/email/translations/personal-email.translations';
import accessKeyChangeTranslations from 'containers/security-keys/views/access-key-change/translations/security-keys-access-key-change.translations';
import accessPortabilityTranslations from 'containers/personal/views/access-portability/views/landing/translations/access-portability.translations';
import activateCardTranslations from 'containers/card-settings/views/activate-card/translations/card-settings-activate-card.translations';
import cardControlsTranslations from 'containers/card-settings/views/card-control/translations/settings-card-control.translations';
import cardSettingsTranslations from 'containers/card-settings/translations/card-settings.translations';
import cardStatementsTranslations from 'containers/card-settings/views/statements/translations/card-settings-statements.translations';
import checkCvvPinTranslations from 'containers/card-settings/views/check-cvv-pin/translations/check-cvv-pin.translations';
import dashboardTranslations from 'containers/dashboard/translations/dashboard.translations';
import fastCashTranslations from 'containers/financing/translations/fast-cash.translations';
import financingTranslations from 'containers/financing/translations/financing.translations';
import ibanAndBillingTranslations from 'containers/iban-billings/translations/iban-billings.translations';
import notificationsTranslations from 'containers/notifications/translations/notifications.translations';
import { PERSONAL_INFORMATION } from 'containers/personal/views/landing/translations/personal-landing.translations';
import pointsTranslations from 'containers/points/translations/points.translations';
import requestSignatureKeyTranslations from 'containers/security-keys/views/request-signature-key/translations/request-signature-key.translations';
import securityTranslations from 'containers/security-keys/views/landing/translations/security-keys-landing.translations';
import signatureKeyChangeTranslations from 'containers/security-keys/views/signature-key-change/translations/security-keys-signature-key-change.translations';
import supportTranslations from 'containers/support/views/landing/translations/support-landing.translations';
import transactionsTranslations from 'containers/transactions/views/landing/translations/transactions-landing.translations';
import { PAGE_TITLE as CREATE_INSTALLMENT_PLAN_TITLE } from 'containers/transactions/views/epp/translations/transactions-epp.translations';

export default {
  [RoutesEnumeration.ACCESS_KEY]: accessKeyChangeTranslations.CHANGE_ACCESS_KEY_TITLE,
  [RoutesEnumeration.ACCESS_PORTABILITY]: accessPortabilityTranslations.TITLE,
  [RoutesEnumeration.ACCESS_REQUEST]: accessPortabilityTranslations.ACCESS_TITLE,
  [RoutesEnumeration.ACTIVATE]: activateCardTranslations.TITLE,
  [RoutesEnumeration.BENEFITS_INSURANCE]: BENEFITS_INSURANCE_TITLE,
  [RoutesEnumeration.BLOCK]: BLOCK_TITLE,
  [RoutesEnumeration.CANCEL_CONTRACT]: CANCEL_CONTRACT_TITLE,
  [RoutesEnumeration.CARD_CONTROL]: cardControlsTranslations.TITLE,
  [RoutesEnumeration.CARD_SETTINGS]: cardSettingsTranslations.TITLE,
  [RoutesEnumeration.CARD_STATEMENTS]: cardStatementsTranslations.TITLE,
  [RoutesEnumeration.CHANGE_3D_SECURE]: CHANGE_3D_TITLE,
  [RoutesEnumeration.CHANGE_BILLING_CYCLE]: CHANGE_BILLING_CYCLE_TITLE,
  [RoutesEnumeration.CHANGE_IBAN]: CHANGE_IBAN_TITLE,
  [RoutesEnumeration.CHANGE_PIN]: CHANGE_PIN_TITLE,
  [RoutesEnumeration.CHECK_3D_SECURE]: CHECK_3D_TITLE,
  [RoutesEnumeration.CONSENTS]: CONSENTS_TITLE,
  [RoutesEnumeration.CONTRACT]: CONTRACT_TITLE,
  [RoutesEnumeration.CREDIT_LIMIT_INCREASE]: INCREASE_LIMIT_TITLE,
  [RoutesEnumeration.CREATE_INSTALLMENTS_PLAN]: CREATE_INSTALLMENT_PLAN_TITLE,
  [RoutesEnumeration.CVV]: checkCvvPinTranslations[checkCvvPinConstants.CVV]?.TITLE,
  [RoutesEnumeration.DASHBOARD]: dashboardTranslations.TITLE,
  [RoutesEnumerationCC.DENIED_MOVEMENTS]: DENIED_MOVEMENTS_TITLE,
  [RoutesEnumeration.DEPOSIT_MONEY]: DEPOSIT_MONEY_TITLE,
  [RoutesEnumeration.DEVICES]: DEVICES_TITLE,
  [RoutesEnumeration.DOCUMENTATION]: DOCUMENTATION_TITLE,
  [RoutesEnumeration.EARLY_REPAYMENT]: EARLY_REPAYMENT_TITLE,
  [RoutesEnumeration.EDIT_ADDRESS]: editAddressTranslations.TITLE,
  [RoutesEnumeration.EDIT_EMAIL]: editEmailTranslations.TITLE,
  [RoutesEnumeration.EDIT_PHONE_NUMBER]: EDIT_PHONE_NUMBER_TITLE,
  [RoutesEnumeration.EDIT_PHOTO]: PHOTO_TITLE,
  [RoutesEnumeration.FAQS]: FAQS_TITLE,
  [RoutesEnumeration.FAST_CASH]: fastCashTranslations.TITLE,
  [RoutesEnumeration.FINANCING]: financingTranslations.TITLE,
  [RoutesEnumeration.IBAN_AND_BILLING]: ibanAndBillingTranslations.TITLE,
  [RoutesEnumeration.MANAGE_DEVICE]: MANAGE_DEVICE_TITLE,
  [RoutesEnumeration.MODIFY_LIMIT]: MODIFY_LIMIT_TITLE,
  [RoutesEnumeration.NOTIFICATIONS_SETTINGS]: NOTIFICATIONS_SETTINGS_TITLE,
  [RoutesEnumeration.NOTIFICATIONS]: notificationsTranslations.TITLE,
  [RoutesEnumeration.OTP]: OTP_TITLE,
  [RoutesEnumeration.PACKAGES]: PACKAGES_TITLE,
  [RoutesEnumeration.PAYMENT_METHOD]: PAYMENT_METHOD_TITLE,
  [RoutesEnumeration.PERSONAL_INFORMATION]: PERSONAL_INFORMATION,
  [RoutesEnumeration.PIN]: checkCvvPinTranslations[checkCvvPinConstants.PIN]?.TITLE,
  [RoutesEnumeration.POINTS]: pointsTranslations.TITLE,
  [RoutesEnumeration.PORTABILITY_REQUEST]: accessPortabilityTranslations.PORTABILITY_TITLE,
  [RoutesEnumeration.PPI_CANCELLATION]: PPI_CANCELLATION_TITLE,
  [RoutesEnumeration.PPI_CONTRACTING]: PPI_CONTRACTING_TITLE,
  [RoutesEnumeration.PPI]: PPI_TITLE,
  [RoutesEnumeration.RECOVERY_KEY]: RECOVERY_KEY_TITLE,
  [RoutesEnumeration.REPORT_A_PROBLEM]: REPORT_A_PROBLEM_TITLE,
  [RoutesEnumeration.REQUEST_NEW_CARD]: REQUEST_NEW_CARD_TITLE,
  [RoutesEnumeration.REQUEST_PARTNER_CARD]: REQUEST_PARTNER_CARD_TITLE,
  [RoutesEnumeration.REQUEST_SIGNATURE_KEY]: requestSignatureKeyTranslations.TITLE,
  [RoutesEnumeration.SECURITY]: securityTranslations.TITLE,
  [RoutesEnumeration.SIGNATURE_KEY]: signatureKeyChangeTranslations.CHANGE_SIGNATURE_KEY_TITLE,
  [RoutesEnumeration.SUPPORT]: supportTranslations.TITLE,
  [RoutesEnumeration.TRANSACTIONS]: transactionsTranslations.TITLE,
  [RoutesEnumerationCC.AUTOMATIC_RENEWAL]: AUTOMATIC_RENEWAL_TITLE,
  [RoutesEnumerationCC.BLOCKED_CARDS]: BLOCKED_CARDS_TITLE,
  [RoutesEnumerationCC.RESET_PIN]: RESET_PIN_TITLE,
};
