// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Resolvers
import { getDocumentByTypeResolver } from '../resolvers/transactions-table-header-link-bar-download-modal.resolvers';
// Translations
import { DOWNLOAD_ERROR } from '../translations/transactions-table-header-link-bar-download-modal.translations';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalReturnHandlersType } from './types/transactions-table-header-link-bar-download-modal-return.handlers.type';
import { TransactionsTableHeaderLinkBarDownloadModalHandlersPropsType } from './types/transactions-table-header-link-bar-download-modal.handlers.type';
import { CloseDownloadModalType } from './types/close-dowload-modal.type';
// Utilities
import { downloadFile } from 'utils/download-file';
import { getFileTypeAndExtension } from '../utils/transactions-table-header-link-bar-download-modal.utils';

const downloadTransactions = async ({
  cardId,
  documentType,
  formValuesParams,
  handleShowSecurityCheckModal,
  setDocumentType,
  setFetching,
  setIsShowDownloadModal,
  setToastConfiguration,
}): Promise<void> => {
  setFetching(true);
  const [response, error] = await getDocumentByTypeResolver({
    documentType,
    cardId,
    formValuesParams,
  });

  if (error === HttpStatusCodesEnum.FORBIDDEN) {
    handleShowSecurityCheckModal();
  } else if ((error !== HttpStatusCodesEnum.FORBIDDEN && error !== undefined) || !response) {
    setIsShowDownloadModal(false);
    setDocumentType('');
    setToastConfiguration({ type: ToastTypesEnumeration.ERROR, description: DOWNLOAD_ERROR });
  } else {
    const { fileType, fileExtension } = getFileTypeAndExtension(documentType);
    downloadFile(
      { blob: response.movementsCobranded, filename: response.fileName + fileExtension },
      fileType
    );
    setDocumentType('');
  }
  setFetching(false);
};

const downloadButtonClickHandler = ({ documentType, setDocumentType }) => {
  setDocumentType(documentType);
};

const cleanDownloadStateHandler = ({ setDocumentType, setFetching }) => {
  setDocumentType('');
  setFetching(false);
};

const closeDownloadModal = ({
  setIsShowDownloadModal,
  setDocumentType,
}: CloseDownloadModalType): void => {
  setDocumentType('');
  setIsShowDownloadModal(false);
};

const TransactionsTableHeaderLinkBarDownloadModalHandlers = ({
  cardId,
  formValuesParams,
  handleShowSecurityCheckModal,
  setDocumentType,
  setFetching,
  setIsShowDownloadModal,
  setToastConfiguration,
}: TransactionsTableHeaderLinkBarDownloadModalHandlersPropsType): TransactionsTableHeaderLinkBarDownloadModalReturnHandlersType => ({
  handleDownload: ({ documentType }) => {
    downloadTransactions({
      cardId,
      documentType,
      formValuesParams,
      handleShowSecurityCheckModal,
      setDocumentType,
      setFetching,
      setIsShowDownloadModal,
      setToastConfiguration,
    });
  },
  handleOnClickCancel: () => closeDownloadModal({ setIsShowDownloadModal, setDocumentType }),
  handleDownloadButtonClick: documentType =>
    downloadButtonClickHandler({ documentType, setDocumentType }),
  handleCleanDownloadState: () => cleanDownloadStateHandler({ setDocumentType, setFetching }),
});

export { TransactionsTableHeaderLinkBarDownloadModalHandlers };
