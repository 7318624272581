// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Types
import { CardTransactionDetailReportType } from 'types/card-transtions-details-report.type';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const isDownloadDisabled = (): boolean =>
  !getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.DOWNLOAD_TRANSACTIONS);

export { isDownloadDisabled };
